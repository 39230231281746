const mediaEndpoints = {
    uploadFile: '/api/s3/objects/files',
};

// Strings
const stringsEndpoints = {
    listStrings: '/api/strings/stringslist',
    createString: '/api/strings/add-string',
    updateString: '/api/strings/update',
    deleteString: '/api/strings/delete-string',
    mutlipleActionsStrings: '/api/strings/multi-action',
};

// Promotions
const promotionsEndpoints = {
    listPromotions: '/api/promotions',
    createPromotion: '/api/promotions',
    updatePromotion: '/api/promotions',
    deletePromotion: '/api/promotions',
};

// Dashboard users
const usersEndpoints = {
    login: '/api/dashboard/login',
    renewAccessToken: '/api/dashboard/renew-access',
    listUsers: '/api/dashboard/userlist',
    createUser: '/api/dashboard/create-user',
    updateUser: '/api/dashboard/update-user',
    sendUserResetPasswordEmail: '/api/dashboard/forgot-password',
};

// App users
const patientsEndpoints = {
    getPatientById: '/api/patients/get-patient',
    listPatients: '/api/patients?',
    createPatient: '/api/patients/create-patient',
    updatePatient: '/api/patients/update-patient',
    sendPatientResetPasswordEmail: '/api/patients/forgot-password',
    listPatientEvents: '/api/patients/list-patient-events',
};

const eventsEndpoints = {
    getEventsData: '/api/events/eventslist',
};

const leadsEndpoints = {
    getLeadById: '/api/leads/find',
};

const offersEndpoints = {
    getOffersList: '/api/offers/',
    getOfferTemplates: '/api/offers/list-templates',
    getOfferTemplateById: '/api/offers/find-template',
    createOffer: '/api/offers/create-offer',
    getOfferById: '/api/offers/find-offer',
};

const callsServiceHost = 'https://calls-prod.elithair.tech/api';
const offersServiceHerokuHost = 'https://offer-services.herokuapp.com';
const treatmentsServiceBaseUrl = 'https://treatment-svc-33fcc29d66d1.herokuapp.com/treatment-svc';
const templates = 'templates';
const sections = 'sections';
//  const offersServiceHerokuHost = 'http://localhost:5001';
// const callsServiceHost = 'http://localhost:8002/api';

const twilioEndpoints = {
    // getCallsList: '/twilio',
    getCallsList: `${callsServiceHost}/twilio/?prefix=CallMachine_CA&limit=1000`,
    getCallById: `${callsServiceHost}/twilio`,
};

const machineCallerEndpoints = {
    postMachineCallerList: `${callsServiceHost}/machine-caller`,
    getMachineCallerScripts: `${callsServiceHost}/machine-caller/scripts`,
    getMachineCallerState: `${callsServiceHost}/machine-caller/status`,
    postStartMachineCaller: `${callsServiceHost}/machine-caller/start`,
    postStopMachineCaller: `${callsServiceHost}/machine-caller/stop`,
    getMachineCalls: `${callsServiceHost}/machine-caller?prefix=CallMachine_CA&limit=1000`,
    getMachineCallById: `${callsServiceHost}/machine-caller`,
};

const templatesEndpoints = {
    getTemplateList: `${offersServiceHerokuHost}/${templates}`,
    getTemplateContent: `${offersServiceHerokuHost}/${templates}/template-content`,
    getTemplateContentWithSection: `${offersServiceHerokuHost}/${templates}/template-raw-content`,
    createOffer: `${offersServiceHerokuHost}/generate-offer`,
    deleteTemplate: `${offersServiceHerokuHost}/${templates}`,
    createTemplate: `${offersServiceHerokuHost}/${templates}`,
    updateTemplate: `${offersServiceHerokuHost}/${templates}`,
    updateTemplateContent: `${offersServiceHerokuHost}/update-template-content-by-template-and-contentid`,
};

const sectionssEndpoints = {
    getSections: `${offersServiceHerokuHost}/${sections}`,
    getSectionsList: `${offersServiceHerokuHost}/${sections}`,
    deleteSection: `${offersServiceHerokuHost}/${sections}`,
    getSectionVersions: `${offersServiceHerokuHost}/${sections}/section-versions-and-contents`,
    updateSection: `${offersServiceHerokuHost}/${sections}`,
    createSection: `${offersServiceHerokuHost}/${sections}`,
    getLatestVersionSections: `${offersServiceHerokuHost}/${sections}/latest-version-sections`,
    updateToLatestVersionSectionsInTemplateContent: `${offersServiceHerokuHost}/${sections}/update-sections-in-template-content`,
};

const extraTreatmentsEndpoints = {
    getExtraTreatments: `${treatmentsServiceBaseUrl}/treatment/list`,
    createExtraTreatment: `${treatmentsServiceBaseUrl}/treatment/create`,
    updateExtraTreatment: `${treatmentsServiceBaseUrl}/treatment/update`,
    deleteExtraTreatment: `${treatmentsServiceBaseUrl}/treatment`,
};

const endpoints = {
    ...mediaEndpoints,
    ...stringsEndpoints,
    ...promotionsEndpoints,
    ...usersEndpoints,
    ...patientsEndpoints,
    ...leadsEndpoints,
    ...offersEndpoints,
    ...twilioEndpoints,
    ...machineCallerEndpoints,
    ...eventsEndpoints,
    ...templatesEndpoints,
    ...sectionssEndpoints,
    ...extraTreatmentsEndpoints,
};

export default endpoints;
