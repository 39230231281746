// components
import TranslateIcon from '@mui/icons-material/Translate';
import PaymentIcon from '@mui/icons-material/Payment';
import SvgIconStyle from '../../../components/SvgIconStyle';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import RequestQuote from '@mui/icons-material/RequestQuote';
import InventoryIcon from '@mui/icons-material/Inventory';
// import HandshakeIcon from '@mui/icons-material/Handshake';
// import AssessmentIcon from '@mui/icons-material/Assessment';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  translate: <TranslateIcon />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_invoice'),
  payment: <PaymentIcon />,
  groups: <GroupsIcon />,
  foodIcon: <EmojiFoodBeverageIcon />,
  requestQuoteIcon: <RequestQuote />,
  inventoryIcon: <InventoryIcon />,
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Offers', path: '/dashboard/offers', icon: ICONS.invoice },
      {
        title: 'Templates',
        path: '/dashboard/templates',
        icon: ICONS.ecommerce,
      },
      {
        title: 'Sections',
        path: '/dashboard/sections',
        icon: ICONS.foodIcon,
      },
      // { title: 'Two', path: '/dashboard/two', icon: ICONS.ecommerce },
      // { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics },
    ],
  },

  // Mobile APP MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Mobile App',
    items: [
      {
        title: 'App Users',
        path: '/dashboard/patients',
        icon: ICONS.user,
      },
      {
        title: 'Strings',
        path: '/dashboard/strings',
        icon: ICONS.translate,
      },
      {
        title: 'Extra Treatments',
        path: '/dashboard/extra-treatments',
        icon: ICONS.inventoryIcon,
      },
      {
        title: 'Promotions',
        path: '/dashboard/promotions',
        icon: ICONS.requestQuoteIcon,
      },
    ],
  },

  // TWILIO
  // ----------------------------------------------------------------------
  {
    subheader: 'Twilio',
    items: [
      {
        title: 'Machine Caller',
        path: '/dashboard/machine-caller',
        icon: ICONS.user,
      },
    ],
  },

  // PATIENTS
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Patients',
  //   items: [
  //     { title: 'PRP Patients', path: '/dashboard/prp-patients', icon: ICONS.groups },
  //     { title: 'HP Patients', path: '/dashboard/hp-patients', icon: ICONS.groups },
  //   ],
  // },

  // PAYMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Payment',
  //   items: [
  //     { title: 'PRP Point of Sale', path: '/dashboard/prp-pos', icon: ICONS.payment },
  //     { title: 'HP Point of Sale', path: '/dashboard/hp-pos', icon: ICONS.payment },
  //   ],
  // },

  // AFFILIATE
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Affiliate',
  //   items: [
  //     { title: 'Partners', path: '/dashboard/partners', icon: <HandshakeIcon /> },
  //     { title: 'Reports', path: '/dashboard/partner-reports', icon: <AssessmentIcon /> },
  //   ],
  // },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Settings',
    items: [{ title: 'Users', path: '/dashboard/users', icon: ICONS.user }],
  },
];

export default sidebarConfig;
