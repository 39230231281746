import jwtDecode from 'jwt-decode';

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const getSession = () => {
  try {
    return JSON.parse(localStorage.getItem('session'))
  } catch (error) {
    console.log(error);
    return undefined;
  }
}
export const setSession = (session) => localStorage.setItem('session', JSON.stringify(session));
export const removeSession = () => localStorage.removeItem('session');

export const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
export const setUser = (user) => localStorage.setItem('user', JSON.stringify(user));
export const removeUser = () => localStorage.removeItem('user');

export const getAccessToken = () => {
  const session = getSession();
  return session?.accessToken?.token;
};

export const getRefreshToken = () => {
  const session = getSession();
  return session?.refreshToken;
};

export const getDeviceId = () => {
  const session = getSession();
  return session?.deviceId;
}
