// scroll bar
// import 'simplebar/src/simplebar.css';

// lightbox
// import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// MUI Localization
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import App from './App';

// ----------------------------------------------------------------------

const domNode = document.getElementById('root');
const root = ReactDOM.createRoot(domNode);

root.render(
    <HelmetProvider>
        <SettingsProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CollapseDrawerProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </CollapseDrawerProvider>
            </LocalizationProvider>
        </SettingsProvider>
    </HelmetProvider>
);
